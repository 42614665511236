import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { useNuxtApp } from '#app'
import ClientRepository from '~/repositories/ClientRepository'

const nuxtApp = useNuxtApp()

export const useTimerStore = defineStore('timer', {
    state: () => ({
        startAt: null,
        client: null
    }),

    actions: {
        start (client) {
            this.startAt = dayjs().format('YYYY-MM-DD HH:mm:ss')
            this.client = client
        },
        stop () {
            this.startAt = null
            this.client = null
        },

        startTimer (client) {
            this.start(client)
        },

        stopAndLog () {
            const formData = {
                start_at: this.startAt,
                end_at: dayjs().format('YYYY-MM-DD HH:mm:ss')
            }
            const client = this.client

            return ClientRepository.logTimer(client.id, formData)
                .then((response) => {
                    this.stop()
                    nuxtApp.vueApp.config.globalProperties.$toast.success({ title: 'Timer', message: 'Temps correctement enregistré' })
                })
        }
    },

    getters: {
        isActive (state) {
            return !!state.startAt
        }
    },

    persist: true

})
