<template>
    <UiButtonModal
        v-if="shoppingCart.length"
        id="shoppingcart"
    >
        <template #trigger>
            <button class="relative btn btn-icon btn-primary">
                <span class="absolute flex items-center justify-center w-5 h-5 text-xs text-white rounded-full -top-3 -right-3 bg-blue-dark">{{ shoppingCart.length }}</span>
                <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>
            </button>
        </template>
        <div class="pb-20 space-y-6">
            <div class="title">
                Shopping Cart
            </div>
            <div>
                <div class="flow-root">
                    <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200"
                    >
                        <li
                            v-for="contact in shoppingCart"
                            :key="contact.id"
                            class="py-4"
                        >
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <img
                                        class="w-8 h-8 rounded-full"
                                        :src="contact.avatar"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        {{ contact.full_name }}
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        {{ contact.email }}
                                    </p>
                                    <p
                                        v-if="contact.metadata"
                                        class="text-sm text-primary"
                                    >
                                        <span v-if="contact.metadata.funderCompanyId">Financeur</span>
                                        <span v-if="contact.metadata.bankId">Banque</span>
                                        <span v-if="contact.metadata.clientId">Client ({{ contact.metadata.type }})</span>
                                    </p>
                                </div>
                                <div>
                                    <button
                                        class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                        @click="removeFromShoppingCart(contact)"
                                    >
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="grid grid-cols-3 gap-4 mt-6">
                    <button
                        class="justify-center btn btn-red"
                        @click="reset"
                    >
                        Vider la liste
                    </button>
                    <button
                        class="justify-center btn btn-green"
                        :disabled="isLoading"
                        :class="{ loading: isLoading }"
                        @click="excel"
                    >
                        Exporter en excel
                    </button>

                    <button
                        class="justify-center w-full btn"
                        @click="showAttachToClient = !showAttachToClient"
                    >
                        Lier à un client
                    </button>
                </div>
            </div>

            <UiSelect
                v-if="showAttachToClient"
                v-model="attachToClient"
                :options="clientOptions"
                placeholder="Choisir un client"
                name="attachToClient"
                required
                :loading="loadingStates['clients.search']"
                @searched="onClientSearch"
            />

            <button
                v-if="attachToClient"
                class="btn btn-primary"
                :disabled="submitting > 0"
                :class="{ loading: submitting > 0 }"
                @click="createClientTabData"
            >
                Soumettre
            </button>
        </div>
    </UiButtonModal>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { loadingMixin, downloadMixin } from 'abcreche-ui'
import clientOptions from '~/mixins/client/clientOptions'
import { useContactsStore } from '~/stores/contacts'
import ClientRepository from '~/repositories/ClientRepository'

export default {
    mixins: [loadingMixin, downloadMixin, clientOptions],

    emits: ['updated'],

    data () {
        return {
            showAttachToClient: false,
            attachToClient: null,
            submitting: 0
        }
    },

    computed: {
        ...mapState(useContactsStore, ['shoppingCart'])
    },

    methods: {
        ...mapActions(useContactsStore, ['removeFromShoppingCart', 'resetShoppingCart']),

        excel () {
            this.load()
            this.$contactRepository.export({ ids: this.shoppingCart.map(c => c.id) })
                .then((response) => {
                    this.done()
                    this.downloadFileFromResponse(response)
                })
                .catch(error => this.pageError(error))
        },

        createClientTabData () {
            this.submitting = this.shoppingCart.length

            const clientId = this.attachToClient

            this.shoppingCart.forEach((contact) => {
                const formData = {
                    staff_id: this.$user.id,
                    contact_id: contact.id,
                    amount: 0,
                    task_title: 'todo',
                    layer: 1
                }

                if (contact.metadata.funderCompanyId) {
                    formData.funder_company_id = contact.metadata.funderCompanyId

                    ClientRepository.createFunder(clientId, formData)
                        .then((response) => {
                            this.$emit('updated')
                            this.submitting--
                            this.checkSubmittingDone()
                        })
                        .catch(error => this.doneWithErrors(error))
                } else if (contact.metadata.bankId) {
                    formData.bank_id = contact.metadata.bankId

                    ClientRepository.createBank(clientId, formData)
                        .then((response) => {
                            this.$emit('updated')
                            this.submitting--
                            this.checkSubmittingDone()
                        })
                        .catch(error => this.doneWithErrors(error))
                } else if (contact.metadata.clientId) {
                    formData.client_company_id = contact.metadata.clientId
                    formData.type = 'clientcompany'
                    formData.acquisition_type = contact.metadata.type

                    ClientRepository.createAcquisition(clientId, formData)
                        .then((response) => {
                            this.$emit('updated')
                            this.submitting--
                            this.checkSubmittingDone()
                        })
                        .catch(error => this.doneWithErrors(error))
                }
            })
        },

        checkSubmittingDone () {
            if (this.submitting === 0) {
                this.done('Les contacts ont été attaché à la société client')
                this.$modal.closeAll()
            }
        },

        onClientSearch (value) {
            this.clientFilters.search = value
            this.clientFilters.status = []
            this.searchClients()
        }
    }
}
</script>
