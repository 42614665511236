<template>
    <div class="relative flex flex-1">
        <label
            for="search-field"
            class="sr-only"
        >Search</label>
        <div class="absolute pointer-events-none inset-y-0 left-0 h-full w-5 flex items-center">
            <span
                v-if="isLoading"
                class="loading gray-dark"
            />
            <MagnifyingGlassIconMini
                v-else
                class=" text-gray-400"
            />
        </div>

        <input
            id="search-field"
            ref="globalSearch"
            v-model="searchTerm"
            class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
        >
        <div
            v-if="shouldShowResults"
            class="absolute top-[100%] w-full mt-1 bg-white rounded-md shadow-lg"
        >
            <div class="py-1 overflow-auto text-base rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <nuxt-link
                    v-for="result in results"
                    id="listbox-option-0"
                    :key="result.id"
                    :to="`/client/${result.id}`"
                    role="option"
                    class="relative block py-2 pl-3 text-gray-900 cursor-default select-none hover:text-gray-100 hover:bg-primary-500 pr-9"
                >
                    <span class="block font-normal truncate">
                        {{ result.name }}
                    </span>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { loadingMixin } from 'abcreche-ui'
import ClientRepository from '~/repositories/ClientRepository'

export default {
    mixins: [loadingMixin],

    data () {
        return {
            searchTerm: '',
            shouldShowResults: false
        }
    },

    watch: {
        searchTerm () {
            this.searchClients()
        }
    },

    created () {
        window.addEventListener('click', this.documentClick)
    },

    unmounted () {
        window.removeEventListener('click', this.documentClick)
    },

    methods: {
        documentClick (e) {
            const el = this.$refs.globalSearch
            const target = e.target
            if (el !== target && !el.contains(target)) {
                this.shouldShowResults = false
            }
        },
        searchClients: _.debounce(function (e) {
            this.load()
            ClientRepository.index({ search: this.searchTerm })
                .then((response) => {
                    this.results = response.data
                    this.shouldShowResults = true
                    this.done()
                })
        }, 300)
    }
}
</script>
