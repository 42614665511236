<template>
    <div
        class="mt-1 space-y-1"
        role="group"
        aria-labelledby="teams-headline"
    >
        <NuxtLink
            v-for="activity in activities"
            :key="activity.id"
            :to="activity.properties && activity.properties.client_id ? `/client/${activity.properties.client_id}` : feedActivityLink(activity)"
            class="flex items-center px-3 py-2 space-x-2 text-sm font-medium text-gray-300 rounded-md group hover:text-gray-900 hover:bg-gray-50"
        >
            <div class="w-4">
                <PhoneIconMicro
                    v-if="activity.log_name === 'phone'"
                    class="w-4 h-4 text-indigo-500"
                />
                <BuildingOfficeIconMicro
                    v-if="activity.log_name === 'fundercompany'"
                    class="w-4 h-4 text-blue-500"
                />
                <svg
                    v-if="activity.log_name === 'funder'"
                    data-funder
                    class="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                /></svg>
                <svg
                    v-if="activity.log_name === 'productcontact'"
                    data-productcontact
                    class="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                /></svg>
                <BuildingLibraryIconMicro
                    v-if="activity.log_name === 'bank'"
                    class="w-4 h-4 text-indigo-500"
                />
                <svg
                    v-if="activity.log_name === 'client.product'"
                    data-client.product
                    class="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>
                <svg
                    v-if="activity.log_name === 'product'"
                    data-product
                    class="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>
                <BanknotesIconMicro
                    v-if="activity.log_name === 'client.fundercompany'"
                    class="w-4 h-4 text-green-500"
                />
                <BookOpenIconMicro
                    v-if="activity.log_name === 'client.advisory'"
                    class="w-4 h-4 text-rose-500"
                />
                <BuildingLibraryIconMicro
                    v-if="activity.log_name === 'client.bank'"
                    class="w-4 h-4 text-indigo-500"
                />
                <ChatBubbleBottomCenterTextIconMicro
                    v-if="activity.log_name === 'funder-company.comment'"
                    class="w-4 h-4 text-orange-500"
                />
                <ChatBubbleBottomCenterTextIconMicro
                    v-if="activity.log_name === 'client.comment'"
                    class="w-4 h-4 text-orange-500"
                />
                <ChatBubbleBottomCenterTextIconMicro
                    v-if="activity.log_name === 'contact.comment'"
                    class="w-4 h-4 text-orange-500"
                />
                <ChatBubbleBottomCenterTextIconMicro
                    v-if="activity.log_name === 'user.comment'"
                    class="w-4 h-4 text-orange-500"
                />
                <ChatBubbleBottomCenterTextIconMicro
                    v-if="activity.log_name === 'partner.comment'"
                    class="w-4 h-4 text-orange-500"
                />
                <UserCircleIconMicro
                    v-if="activity.log_name === 'client'"
                    class="w-4 h-4 text-blue-500"
                />
                <CheckCircleIconMicro
                    v-if="activity.log_name === 'task'"
                    class="w-4 h-4 text-primary-500"
                />
                <PaperClipIconMicro
                    v-if="activity.log_name === 'media'"
                    class="w-4 h-4 text-red-500"
                />
                <UserIconMicro
                    v-if="activity.log_name === 'client.updated'"
                    class="w-4 h-4 text-blue-500"
                />
                <UserIconMicro
                    v-if="activity.log_name === 'client.status.updated'"
                    class="w-4 h-4 text-blue-500"
                />
                <svg
                    v-if="activity.log_name === 'funder.contact-event'"
                    data-funder.contact-event
                    class="w-4 h-4 text-black"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                ><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" /></svg>
                <FolderIconMicro
                    v-if="activity.log_name === 'folder'"
                    class="w-4 h-4 text-red-500"
                />
            </div>
            <div class="truncate">
                <p class="text-sm truncate">
                    {{ activity.short_name }}
                </p>
                <p class="text-xs text-gray-400">
                    {{ activity.causer.full_name }} - {{ $filters.format(activity.created_at, 'DD/MM HH:mm') }}
                </p>
            </div>
        </NuxtLink>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { loadingMixin } from 'abcreche-ui'
import { useAuthStore } from '~/stores/auth'

export default {
    mixins: [loadingMixin],
    data () {
        return {
            activities: []
        }
    },

    computed: {
        ...mapState(useAuthStore, ['user'])
    },

    created () {
        this.getFeed()
    },

    methods: {
        getFeed () {
            this.$activityRepository.index({ limit: 10 })
                .then((response) => {
                    this.activities = response.data
                })
                .catch(error => this.pageError(error))
        },

        feedActivityLink (activity) {
            if (activity.subject_type && activity.subject_type.includes('Bank')) {
                return `/bank/${activity.subject_id}`
            } else if (activity.subject_type && activity.subject_type.includes('Client')) {
                return `/client/${activity.subject_id}`
            } else if (activity.subject_type && activity.subject_type.includes('FunderCompany')) {
                return `/fundercompany/${activity.subject_id}`
            } else if (activity.subject_type && activity.subject_type.includes('Funder')) {
                return `/fundercompany/${activity.subject.funder_company_id}`
            } else if (activity.subject && activity.subject.contactable_type) {
                if (activity.subject.contactable_type.includes('Funder')) {
                    return `/fundercompany/${activity.subject.contactable_id}/contact/${activity.subject.contactable_id}`
                }
            }
            return '/'
        }
    }
}
</script>
