<template>
    <Transition appear>
        <div
            v-if="isActive"
            class="flex items-center justify-between p-2 text-sm text-white bg-primary-500 rounded-lg shadow-md"
        >
            <span class="flex items-center space-x-2">
                <ClockIconMini class="w-5 h-5 text-gray-100" />
                <span>{{ client.name }}</span>
            </span>
            <span class="flex items-center space-x-2">
                <span>{{ secondsInMinutes(seconds) }}</span>
                <button
                    class="flex items-center transition-all justify-center w-6 h-6 border border-white rounded-full hover:bg-gray-200 text-white hover:text-gray-700"
                    title="stop timer"
                    @click="stopAndLog"
                >
                    <StopIconMini
                        v-if="isActive"
                        class="size-3"
                    />
                </button>

            </span>
        </div>
    </Transition>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import dayjs from 'dayjs'
import { useTimerStore } from '~/stores/timer'

export default {
    data () {
        return {
            seconds: 0,
            timer: null
        }
    },

    computed: {
        ...mapState(useTimerStore, ['isActive', 'startAt', 'client'])
    },

    watch: {
        isActive () {
            this.timer = setInterval(() => {
                this.seconds = dayjs().diff(this.startAt, 'second')
            }, 1000)
        }
    },

    created () {
        if (this.isActive) {
            this.timer = setInterval(() => {
                this.seconds = dayjs().diff(this.startAt, 'second')
            }, 1000)
        }
    },

    methods: {
        ...mapActions(useTimerStore, ['stopAndLog']),
        secondsInMinutes (seconds) {
            return dayjs('2015-01-01')
                .startOf('day')
                .set('seconds', seconds)
                .format('HH:mm:ss')
        }
    }
}
</script>
